import Vue from 'vue'
import Vuex from 'vuex'

import ApiService from '@/services/api.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    portalsettings: {
      widget: false,
      retailer: false
    },

    translations: null,

    languages: [],
    language: {
      id: null,
      name: '',
      lang: ''
    },

    subjects: [],
    subject: null,

    countries: [],
    country: null,

    retailers: [],
    retailer: null,

    stores: [],
    store: null,

    brands: [],
    brand: null,

    products: [],
    product: null,
    productInfo: null,

    requestStatus: []
  },
  mutations: {
    // Widget
    setSettingWidget (state, value) {
      state.portalsettings.widget = value
    },
    // Retailer
    setSettingRetailer (state, value) {
      state.portalsettings.retailer = value
    },
    getTranslations (state) {
      ApiService.get('/Translations', {
        languageId: state.language.id
      })
        .then(response => {
          state.translations = response.data.data.translations
        })
        .catch(error => {
          console.log(error)
          state.translations = []
        })
    },
    // Language
    getLanguages (state) {
      ApiService.get('/Languages')
        .then(response => {
          state.languages = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.languages = []
        })
    },
    setLanguage (state, lang) {
      localStorage.setItem('lang', lang)
      var activeLanguage = state.languages.find(function (item) { return item.lang === lang })
      state.language = activeLanguage

      this.commit('getTranslations')
    },
    // Country
    getCountries (state) {
      ApiService.get('/Countries')
        .then(response => {
          state.countries = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.countries = []
        })
    },
    setCountry (state, countryId) {
      state.country = countryId
    },
    clearCountries (state) {
      state.countries = []
      this.commit('clearCountry')
    },
    clearCountry (state) {
      state.country = null
    },
    // Retailer
    getRetailers (state) {
      ApiService.get('/Retailers', {
        countryId: state.country
      })
        .then(response => {
          state.retailers = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.retailers = []
        })
    },
    setRetailer (state, retailerId) {
      state.retailer = retailerId
    },
    clearRetailers (state) {
      state.retailers = []
      this.commit('clearRetailer')
    },
    clearRetailer (state) {
      state.retailer = null
    },
    // Store
    getStores (state) {
      ApiService.get('/Stores', {
        countryId: state.country,
        retailerId: state.retailer
      })
        .then(response => {
          state.stores = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.retailers = []
        })
    },
    setStore (state, storeId) {
      state.store = storeId
    },
    clearStores (state) {
      state.stores = []
      this.commit('clearStore')
    },
    clearStore (state) {
      state.store = null
    },
    // Brand
    getBrands (state) {
      ApiService.get('/Brands', {
        countryId: state.country,
        retailerId: state.retailer
      })
        .then(response => {
          state.brands = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.brands = []
        })
    },
    setBrand (state, brandId) {
      state.brand = brandId
    },
    clearBrands (state) {
      state.brands = []
      this.commit('clearBrand')
    },
    clearBrand (state) {
      state.brand = null
    },
    // Products
    getProducts (state) {
      ApiService.get('/Products', {
        countryId: state.country,
        retailerId: state.retailer,
        brandId: state.brand
      })
        .then(response => {
          state.products = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.products = []
        })
    },
    setProduct (state, productId) {
      state.product = productId
      var product = state.products.find(function (item) { return item.id === productId })
      state.productInfo = product
    },
    clearProducts (state) {
      state.products = []
      this.commit('clearProduct')
    },
    clearProduct (state) {
      state.product = null
      state.productInfo = null
    },
    // Subject
    getSubjects (state) {
      ApiService.get('/Subjects', {
        productId: state.product
      })
        .then(response => {
          state.subjects = response.data.data
        })
        .catch(error => {
          console.log(error)
          state.subjects = []
        })
    },
    setSubject (state, subjectId) {
      state.subject = subjectId
    },
    clearSubjects (state) {
      state.subjects = []
      this.commit('clearSubject')
    },
    clearSubject (state) {
      state.subject = null
    },
    // Request status
    setRequestStatus (state, value) {
      state.requestStatus = value
    },
    clairRequestStatus (state) {
      state.requestStatus = []
    }
  },
  actions: {
  },
  modules: {
  }
})
