import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // Portal
  {
    path: '/',
    name: 'Portal',
    component: () => import('@/views/Portal/Index.vue')
  },
  {
    path: '/create-ticket',
    name: 'Portal-CreateTicket',
    component: () => import('@/views/Portal/CreateTicket.vue')
  },
  {
    path: '/request-status',
    name: 'Portal-RequestStatus',
    component: () => import('@/views/Portal/RequestStatus.vue')
  },
  {
    path: '/request-status/result',
    name: 'Portal-RequestStatusResult',
    component: () => import('@/views/RequestStatusResult.vue'),
    props: true
  },
  {
    path: '/create-ticket/:ticketId',
    name: 'Portal-CreateTicket-Success',
    component: () => import('@/views/CreateTicketSuccess.vue'),
    props: true
  },
  // Widget
  {
    path: '/widget',
    name: 'Widget-CreateTicket',
    component: () => import('@/views/Widget/CreateTicket.vue')
  },
  {
    path: '/widget/:ticketId',
    name: 'Widget-CreateTicket-Success',
    component: () => import('@/views/CreateTicketSuccess.vue'),
    props: true
  },
  // Retailer
  {
    path: '/retailer',
    name: 'Retailer-Portal',
    component: () => import('@/views/Retailer/Index.vue')
  },
  {
    path: '/retailer/create-ticket',
    name: 'Retailer-CreateTicket',
    component: () => import('@/views/Retailer/CreateTicket.vue')
  },
  {
    path: '/retailer/request-status',
    name: 'Retailer-RequestStatus',
    component: () => import('@/views/Retailer/RequestStatus.vue')
  },
  {
    path: '/retailer/request-status/result',
    name: 'Retailer-RequestStatusResult',
    component: () => import('@/views/RequestStatusResult.vue'),
    props: true
  },
  {
    path: '/retailer/:ticketId',
    name: 'Retailer-CreateTicket-Success',
    component: () => import('@/views/CreateTicketSuccess.vue'),
    props: true
  },
  // Refund - Replace
  {
    path: '/refund/success',
    name: 'RefundSuccess',
    component: () => import('@/views/RefundReplace_Success.vue')
  },
  {
    path: '/refund/:guid',
    name: 'Refund',
    component: () => import('@/views/Refund.vue'),
    props: true
  },
  {
    path: '/replace/success',
    name: 'ReplaceSuccess',
    component: () => import('@/views/RefundReplace_Success.vue')
  },
  {
    path: '/replace/:guid',
    name: 'Replace',
    component: () => import('@/views/Replace.vue'),
    props: true
  },
  {
    path: '/reject/success',
    name: 'RejectSuccess',
    component: () => import('@/views/Reject_Success.vue')
  },
  {
    path: '/reject/:guid',
    name: 'Reject',
    component: () => import('@/views/Reject.vue'),
    props: true
  },
  // Footer
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import('@/views/TermsOfUse.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  }
  // {
  //   path: '/cookie-statement',
  //   name: 'CookieStatement',
  //   component: () => import('@/views/CookieStatement.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
