import Vue from 'vue'
import store from '@/store'
import ApiService from '@/services/api.service'

Vue.prototype.$t = function (word) {
  var translated = word

  if (store.state.translations != null) {
    translated = store.state.translations.find(function (item) {
      if (item.word === word) {
        return item
      }
    })

    if (translated != null && translated.translated !== '') {
      translated = translated.translated
    } else if (translated != null) {
      translated = translated.word
    } else {
      translated = word

      ApiService.post('/Translations', {
        word: translated
      })
    }
  }

  return translated
}
