<template>
  <div id="app" class="body-flex" :class="this.$store.state.portalsettings.widget ? 'version-widget' : ''">

    <!-- Header -->
    <header v-if="this.$store.state.portalsettings.widget" class="header widget">
       <div class="grid grid-pad">
        <div class="col-1-1 flex align-center justify-end">
          <lang-select></lang-select>
        </div>
      </div>
    </header>
    <header v-else class="header no-widget">
      <div class="grid grid-pad">
        <div class="col-1-1 flex align-center justify-space-between">
          <router-link v-if="this.$store.state.portalsettings.retailer" :to="{name: 'Retailer-Portal'}" class="logo">Brandloyalty</router-link>
          <router-link v-else :to="{name: 'Portal'}" class="logo">Brandloyalty</router-link>
          <lang-select></lang-select>
        </div>
      </div>
    </header>

    <!-- Main -->
    <main class="main">
      <router-view/>
    </main>

    <!-- Footer -->
    <footer v-if="!this.$store.state.portalsettings.widget" class="footer">
      <svg class="footer-shape" xmlns="http://www.w3.org/2000/svg" width="118" height="201" viewBox="0 0 118 201"><g><g><path fill="#0a0f41" d="M0 200.107v-200l61.728 161.518c8.856 23.172 31.105 38.482 55.932 38.482z"></path></g></g></svg>
      <div class="grid grid-pad">
         <div class="col-1-1 flex align-center justify-space-between sm-justify-center">
            <div class="logo">Brandloyalty</div>
            <div class="footer-menu">
              <ul class="flex justify-end sm-justify-center">
                <li class="sm-col-1-1">&copy; 2021 Brand Loyalty Sourcing B.V.</li>
                <li><router-link :to="{name: 'TermsOfUse'}" target="_blank">{{ $t("Terms of Use") }}</router-link></li>
                <li><router-link :to="{name: 'Privacy'}" target="_blank">{{ $t("Privacy Statement") }}</router-link></li>
                <!-- <li><router-link :to="{name: 'CookieStatement'}" target="_blank">{{ $t("Cookie  statement") }}</router-link></li> -->
              </ul>
            </div>
          </div>
      </div>
    </footer>
  </div>
</template>

<script>
import LangSelect from '@/components/LangSelect.vue'

export default {
  name: 'App',
  components: {
    LangSelect
  },
  data () {
    return {}
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

 <style lang="scss">
  @import "@/style/variables";
  @import "@/style/mixins";
  @import "@/style/grid";
  @import "@/style/flex";
  @import "@/style/styleguide";
  @import "@/style/form";
  @import "@/style/default";
</style>
