import axios from 'axios'

const ApiService = {

  init (baseURL) {
    axios.defaults.baseURL = baseURL
  },

  setHeader () {
    axios.defaults.headers.common.apiKey = process.env.VUE_APP_API_KEY
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  get (resource, data) {
    return axios.get(resource, { params: data })
  },

  getFile (resource, data) {
    return axios.get(resource, { responseType: 'arraybuffer', params: data })
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  postFile (resource, data) {
    return axios.post(resource, data, { headers: { 'Content-Type': 'multipart/form-data' } })
  },

  put (resource, data) {
    return axios.put(resource, data)
  },

  putFile (resource, data) {
    return axios.put(resource, data, { headers: { 'Content-Type': 'multipart/form-data' } })
  },

  delete (resource, data) {
    return axios.delete(resource, { data: data })
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
  customRequest (data) {
    return axios(data)
  }
}

export default ApiService
