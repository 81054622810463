<template>
  <select v-model="selectedLang" class="lang-select">
    <option v-for="language in this.$store.state.languages" :key="language.id" v-bind:value="language.lang">{{ language.lang }}</option>
  </select>
</template>

<script>

export default {
  name: 'LangSelect',
  props: {},
  data () {
    return {
      selectedLang: ''
    }
  },
  beforeCreate () {
    this.$store.commit('getLanguages')
  },
  mounted () {
    var langInterval = setInterval(() => {
      if (this.$store.state.languages.length > 0) {
        clearInterval(langInterval)

        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())

        if (localStorage.getItem('lang') && this.consisteLang(localStorage.getItem('lang'))) {
          this.selectedLang = localStorage.getItem('lang')
        } else if (this.$route.query.lang && this.consisteLang(this.$route.query.lang)) {
          this.selectedLang = this.$route.query.lang
        } else if (params.lang && this.consisteLang(params.lang)) {
          this.selectedLang = params.lang
        } else if (navigator.language && this.consisteLang(navigator.language)) {
          this.selectedLang = navigator.language
        } else {
          this.selectedLang = 'EN-gb'
        }
      }
    }, 50)
  },
  computed: {},
  methods: {
    consisteLang: function (val) {
      var lang = this.$store.state.languages.find(function (item) { return item.lang === val })
      if (lang) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    selectedLang (val) {
      this.$store.commit('setLanguage', val)
    }
  }
}
</script>
